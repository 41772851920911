import { Link, animateScroll as scroll } from "react-scroll";
import styled from '@emotion/styled';
import { Box, Flex,Text, Image } from "@chakra-ui/react";


const NavLink = styled(Link)`
    padding: 0 1rem;
    font-family: "calder-dark", sans-serif;
    font-weight: 800;
    cursor: pointer;
    color: #747e89;
    &:hover {
        color: #57616c;
    }
    &.active{
        color: #0b3d6f;
        text-decoration: underline;
    }
`;

const NavBar = styled(Flex)`
    background-color: #fff;
    height: 80px;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    align-items: center;
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 999;
`;

const Nav = () => {

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    return(
        <NavBar>
            <Box  sx={{paddingLeft: ".5rem", cursor:"pointer", maxHeight:"60px", height:"60px"}} onClick={() => scrollToTop()}><Image fit="scale-down" sx={{height:"60px"}} src="./assets/logo.png" alt="CBMR" htmlHeight="60" /></Box>
            <Box >
                <Flex sx={{
                    alignItems: "right",
                    padding: "0 1rem 0 1rem"
                }}>
                    <NavLink activeClass="active" to="mission" spy={true} smooth={true} offset={-70}>Mission</NavLink>
                    <NavLink activeClass="active" to="products" spy={true} smooth={true} offset={-70}>Products</NavLink>
                    <NavLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70}>Contact</NavLink>
                </Flex>
            </Box>
        </NavBar>
    )
}

export default Nav;

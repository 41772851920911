import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { FormControl, FormLabel, FormErrorMessage, Select, Input, Textarea, VStack, Button } from '@chakra-ui/react';
import * as Yup from 'yup';
// @ts-ignore  
import emailjs from 'emailjs-com';

interface MyFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  interest: string;
  message: string;
}

const ContactSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Too Short!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  interest: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
  phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, { excludeEmptyString: true })
});

const ContactForm = () => {
  const initialValues : MyFormValues = {
    firstName: '',
    lastName:'',
    email:'',
    phone: '',
    interest: '',
    message: ''
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ContactSchema}
      onSubmit={(values, actions) => {
        console.log(values);
        emailjs.send('service_o8or98a', 'template_67e6wdo', {...values}, 'user_OVVmQ9hfDrNntNbCAJ1rX', )
          .then((result) => {
              console.log(result.text);
              actions.resetForm({});
          }, (error) => {
              console.log(error.text);
          });
            actions.setSubmitting(false);
          }}
    >
      <Form style={{maxWidth: "800px", margin:"0 auto"}}>
        <VStack spacing={8}>
        <Field name="firstName">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.firstName && form.touched.firstName}>
              <FormLabel htmlFor="firstName">First name</FormLabel>
              <Input bg="#fff" size="lg" {...field} id="firstName" />
              <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="lastName">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.lastName && form.touched.lastName}>
              <FormLabel htmlFor="lastName">Last name</FormLabel>
              <Input bg="#fff" size="lg" {...field} id="lastName"  />
              <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="email">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.email && form.touched.email}>
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <Input bg="#fff" size="lg" {...field} id="email"  />
              <FormErrorMessage>{form.errors.email}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="phone">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.phone && form.touched.phone}>
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <Input bg="#fff" size="lg" {...field} id="phone"  />
              <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="interest" as="select">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.interest && form.touched.interest}>
              <FormLabel htmlFor="interest">What product are you inquiring about?</FormLabel>
              <Select bg="#fff" size="lg"  {...field} placeholder="Select option">
                <option value="tetrapore">Tetrapore</option>
                <option value="nuturell">Nuturell</option>
                <option value="seliteq">Seliteq</option>
              </Select>
              <FormErrorMessage>{form.errors.interest}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="message" as="textarea">
          {({ field, form }) => (
            <FormControl isInvalid={form.errors.message && form.touched.message}>
              <FormLabel htmlFor="message">Message</FormLabel>
              <Textarea bg="#fff" size="lg" {...field}   placeholder="message" />
              <FormErrorMessage>{form.errors.message}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Button
            mt={4}
            bg="black"
            color="#fff"
            type="submit"
            size="lg"
          >
            Submit
          </Button>
        </VStack>
      </Form>
    </Formik>
  );
};

export default ContactForm;

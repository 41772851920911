/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
//import { Message } from '@cbmr-apps/api-interfaces';
import styled from '@emotion/styled';
import { Box, Image, Text, SimpleGrid, Button, Center } from "@chakra-ui/react";

import Nav from './components/nav';
import Section from './components/section';
import ContactForm from './components/ContactForm';

const BG = styled(Box)`
  background-image: url('./assets/bg.png');
  height: 100vh;
  max-height: 1998px;
  background-size: cover;
  text-align: center;
  padding-top: 6rem;
`;


export const App = () => {
  // const [m, setMessage] = useState<Message>({ message: '' });

  // useEffect(() => {
  //   fetch('/api')
  //     .then((r) => r.json())
  //     .then(setMessage);
  // }, []);

  return (
    <>
      <Nav />
      <BG>
          <Image src="./assets/cbmr.png" align="center" boxSize="15rem" margin="5rem auto 1rem auto" />
          <Text color="#fff" sx={{fontFamily:"calder-dark", fontWeight: 500, fontSize: "1.5rem"}}>
            CALDER & BRYANT <br /> MANUFACTURERS REPRESENTATIVES
          </Text>
        
      </BG>
      <Section id="mission" title="Our Mission" bg="#fff">
        <Text sx={{fontSize: "1.25rem", color: "#666"}}>The mission of CBMR is to design manufacturing, arrange logistics operations, and ensure the expedited fulfilment of finished products while protecting Intellectual Property. CBMR will deliver these finished products to selected distributors via our exclusive sales team.</Text>
      </Section>
      <Section id="products" title="Our Products" bg="#fff">
        <SimpleGrid columns={{sm: 1, md: 2, lg: 3}} spacing={10}>
          <Box p={8} sx={{border:"1px solid #ccc"}}>
              <Image src="./assets/nuturell.png" />
              <Text>An Eco-friendly, non-toxic product providing exceptional Viral and antimicrobial surface protection.</Text>
              <Button colorScheme="blue" bg="#0b3d6f" mt={6} onClick={() => parent.open('https://nuturell.com/')}>Learn More</Button>
          </Box>
          <Box p={8} sx={{border:"1px solid #ccc"}}>
              <Image src="./assets/tetrapore.png" />
              <Text>An Eco-friendly, non-toxic liquid admixture for concrete based on its recently developed Nano-ceramic Polymer Technology (NPT).</Text>
              <Button colorScheme="blue" bg="#0b3d6f" mt={6} onClick={() => parent.open('https://tetrapore.com/')}>Learn More</Button>
          </Box>
          <Box p={8} sx={{border:"1px solid #ccc"}}>
              <Image src="./assets/seliteq.png" />
              <Text>A Ready To Use (RTU) Active Nano Technology Curing additive solution for slurry sealcoat. </Text>
              {/* <Button colorScheme="blue" bg="#0b3d6f" mt={6} onClick={() => parent.open('https://nuturell.com/')}>Learn More</Button> */}
          </Box>
        </SimpleGrid>
      </Section>
      <Section id="contact" title="Contact Us" bg="#c0dbf5">
        <ContactForm />
      </Section>
      <Center p={3} bg="#0b3d6f"><Text color="#fff">© Copyright 2021 CBMR - All Rights Reserved</Text></Center>
    </>
  );
};

export default App;

import { StrictMode } from 'react';
import { ChakraProvider } from "@chakra-ui/react"
import * as ReactDOM from 'react-dom';

import App from './app/app';
import GlobalStyles from './app/components/GlobalStyles';
import theme from './theme';

ReactDOM.render(
  <StrictMode>
    <ChakraProvider>
      <GlobalStyles />
      <App />
    </ChakraProvider>
  </StrictMode>,
  document.getElementById('root')
);



/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex,Text } from "@chakra-ui/react";

const Container = styled(Box)`
    padding: 2rem 2rem 4rem 2rem;
    background-color: ${({bg}) => bg ? bg : '#fff'};
    text-align: ${({align}) => align ? align : 'center'};
`;

const H1 = styled(Text)`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 2rem;
    font-family: 'calder-dark';
    text-align: center;
`;

interface SectionProps {
    title: string,
    id: string,
    children: any,
    align?: string,
    bg?: string
}

const Section = ({title, id, align, bg, children}:SectionProps) => {
    return(
        <Container id={id} bg={bg} align={align}>
            <H1>{title}</H1>
            {children}
        </Container>
    )
}

export default Section;
